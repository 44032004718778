import { Component } from '@angular/core';
import { BulkOrderService } from '../../services/bulk-order-service';
import { Observable } from 'rxjs/internal/Observable';
import { tap, pipe } from 'rxjs';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';

@Component({
  selector: 'app-content-left',
  templateUrl: './content-left.component.html',
  styleUrl: './content-left.component.scss'
})
export class ContentLeftComponent {
  isActive: boolean = false;
  refurbishedBtn: boolean = false;
  newBtn: boolean = false;
  isSelectedChoose: string = "choosefile";
  isSelectedRefurbished: boolean = true;
  bulkOrder$: Observable<any>;
  isHighlighted: boolean = false;
  currentpage$: Observable<number>
  validFile: any;
  public userRoles: any[];
  role: string;

  constructor(
    public bulkService: BulkOrderService,
    public storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.currentpage$ = this.bulkService.isNext$;
    this.bulkService.isUploadFile$.subscribe((title) => {
      this.validFile = title;
      this.getUserRole();
    })
    this.isSelectedBtn(1)
  }

  getUserRole() {
    let userRole: any = ''
    userRole = this.storageService.userRoles;

    if (userRole?.includes("Requester") && !userRole?.includes("Purchaser")) {
      this.role = 'Requester';
    }
    else if (userRole?.includes("Purchaser") && !userRole?.includes("Requester")) {
      this.role = 'Purchaser';
    }
    else if (userRole?.includes("Purchaser") && userRole?.includes("Requester")) {
      this.role = 'Purchaser&Requester'
    }
  }

  isSelectedBtn(selected: number) {
    this.bulkService.setNext(selected)
  }

}
